// extracted by mini-css-extract-plugin
export var root = "PlasmicNav-module--root--DZDco";
export var rootglobal_mode_dark = "PlasmicNav-module--rootglobal_mode_dark--gimHD";
export var rootglobal_mode_reveal = "PlasmicNav-module--rootglobal_mode_reveal--ElS7j";
export var reveal = "PlasmicNav-module--reveal--jtOiM";
export var revealglobal_mode_reveal = "PlasmicNav-module--revealglobal_mode_reveal--GG5Cg";
export var svg__sqohL = "PlasmicNav-module--svg__sqohL--f9luf";
export var svgglobal_mode_reveal__sqohLv94Jk = "PlasmicNav-module--svgglobal_mode_reveal__sqohLv94Jk--PSwQa";
export var home = "PlasmicNav-module--home--KVTqF";
export var homeglobal_mode_dark = "PlasmicNav-module--homeglobal_mode_dark--OGFUF";
export var homeglobal_mode_reveal = "PlasmicNav-module--homeglobal_mode_reveal--KEdgE";
export var svg__g4Zs = "PlasmicNav-module--svg__g4Zs--5mDBI";
export var svgglobal_mode_dark__g4ZsJYvh = "PlasmicNav-module--svgglobal_mode_dark__g4ZsJYvh--NJpX8";
export var svgglobal_mode_reveal__g4Zsv94Jk = "PlasmicNav-module--svgglobal_mode_reveal__g4Zsv94Jk--f0ayp";
export var svg___4Ydrm = "PlasmicNav-module--svg___4Ydrm--laJeF";
export var svgglobal_mode_dark___4YdrmJYvh = "PlasmicNav-module--svgglobal_mode_dark___4YdrmJYvh--gq+Nj";
export var svgglobal_mode_reveal___4Ydrmv94Jk = "PlasmicNav-module--svgglobal_mode_reveal___4Ydrmv94Jk--ct3n1";